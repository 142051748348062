<template>
  <div>
    <b-modal
      id="modal-comments-list"
      title="Danh sách các nhận xét cho nhóm"
      size="xl"
      centered
      hide-footer
      @hide="resetModal"
      @show="onShow"
    >
      <b-overlay :show="loading">
        <div class="cmt-wrapper">
          <b-row class="mb-4">
            <b-col cols="8" class="pb-0">Lọc ngày coach</b-col>
            <b-col cols="8">
              <b-row>
                <b-col cols="6">
                  <date-picker
                    placeholder="Từ ngày"
                    name="startDate"
                    input-class="form-control"
                    type="date"
                    :format="dateFomartion"
                    value-type="format"
                    :disabled-date="disabledBeforeFromDate"
                    v-model="filterStartDate"
                    @change="selectDate('start', $event)"
                  />
                </b-col>
                <b-col cols="6">
                  <date-picker
                    placeholder="Đến ngày"
                    name="endDate"
                    input-class="form-control"
                    type="date"
                    :format="dateFomartion"
                    value-type="format"
                    :disabled-date="disabledBeforeToDate"
                    v-model="filterEndDate"
                    @change="selectDate('end', $event)"
                  />
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <template-table
            :column="column"
            :data="data"
            :paging="paging"
            :tableAction="false"
            :selectAction="false"
            :searchAction="false"
            @sortBy="handleSort"
          >
            <template v-slot:body="{ item }">
              <td>
                {{ convertTimestampToDate(item.coachDate) }}
              </td>
              <td>{{ item.performerName }}</td>
              <td class="cell-overflow">
                <div v-if="item.comment" class="text-overflow">
                  <span v-html="item.comment"></span>
                </div>
                <div v-else class="text-dark-50">+ Thêm nhận xét...</div>
              </td>
              <td>
                <b-button
                  class="btn btn-edit"
                  size="sm"
                  type="button"
                  @click="onShowCmtFormModal(item.id)"
                >
                  <span class="svg-icon mr-0">
                    <inline-svg
                      src="/media/svg/icons/Neolex/Basic/edit-2.svg"
                    />
                  </span>
                </b-button>
              </td>
            </template>
          </template-table>
        </div>
      </b-overlay>
    </b-modal>
    <CommentFormModal
      :groupId="groupId"
      :coachSelectionId="coachSelectionId"
      @reset="resetCommentFormModal"
    />
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import { tableMixin } from './../mixins/CommentsModal';
import { createNamespacedHelpers } from 'vuex';
import { GET_CUSTOMERS } from '@/core/services/store/customer.module';
import { MODAL_TYPE } from '../../../../core/plugins/constants';
const { mapActions } = createNamespacedHelpers('customer');

export default {
  name: 'CommentsListModal',
  components: {
    'date-picker': DatePicker,
    CommentFormModal: () => import('./CommentFormModal.vue'),
  },

  props: {
    groupId: {
      type: String,
      default: null,
    },
  },

  mixins: [tableMixin],

  data() {
    return {
      // Filters
      filterStartDate: '',
      filterEndDate: '',
      MODAL_TYPE,
      loading: false,
      data: [],
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      sort: {
        by: null,
        order: null,
      },
      startDate: null,
      endDate: null,
      coachSelectionId: null,
    };
  },

  computed: {
    searchParams() {
      return {
        orderBy: this.sort.by ? `${this.sort.by} ${this.sort.order}` : null,
        page: this.paging.page,
        size: this.paging.pageSize,
        fromDate: this.startDate,
        toDate: this.endDate,
        trainingGroupId: this.groupId,
      };
    },
  },

  watch: {
    'paging.page'() {
      this.getData();
    },

    'paging.pageSize'() {
      this.getData();
    },
    'form.comment'(val) {
      if (!val) return;

      this.$validator.reset({ name: 'comment' });
    },
  },

  methods: {
    ...mapActions({ GET_CUSTOMERS }),
    onShow() {
      this.getData();
    },

    async getData() {
      try {
        this.loading = true;

        const { meta, data, error } = await this.$api.get(
          '/CalendarTrainingComment/GetPageCoach1n',
          {
            params: this.searchParams,
          },
        );

        if (!meta.success) {
          return this.showToastrMessage(error.message, 'error');
        }

        this.data = data;
        this.paging.total = meta.total;
      } catch (error) {
        this.showToastrMessage(error.message, 'error');
      } finally {
        this.loading = false;
      }
    },

    showToastrMessage(message, type) {
      if (type === 'error') {
        return this.$toastr.e({
          title: 'Lỗi',
          msg: message,
        });
      }

      return this.$toastr.s({
        title: 'Thành công',
        msg: message,
      });
    },

    handleSort(val) {
      this.sort.by = val.column;
      this.sort.order = val.order;
      this.getData();
    },

    onShowCmtFormModal(id) {
      this.coachSelectionId = id;
      this.resetModal();
      this.$bvModal.hide('modal-comments-list');
      this.$bvModal.show('modal-comment-form');
    },
    resetCommentFormModal() {
      this.coachSelectionId = null;
    },
    resetModal() {
      this.filterStartDate = '';
      this.filterEndDate = '';
      this.loading = false;
      this.data = [];
      this.paging = {
        page: 1,
        pageSize: 10,
        total: 0,
      };
      this.sort = {
        by: null,
        order: null,
      };
      this.startDate = null;
      this.endDate = null;
      this.$emit('reset');
    },
    selectDate(type, date) {
      if (date) {
        if (type === 'start') {
          this.startDate = this.$moment(date, 'DD/MM/YYYY')
            .startOf('date')
            .format('X');
        } else {
          this.endDate = this.$moment(date, 'DD/MM/YYYY')
            .endOf('date')
            .format('X');
        }
      } else {
        if (type === 'start') {
          this.startDate = null;
        } else {
          this.endDate = null;
        }
      }
      this.getData();
    },
    disabledBeforeToDate(date) {
      if (this.filterStartDate) {
        const newDate = this.convertToISODate(this.filterStartDate);
        const deadline = new Date(newDate);
        deadline.setHours(0, 0, 0, 0);

        return date < deadline;
      }
    },
    disabledBeforeFromDate(date) {
      if (this.filterEndDate) {
        const newDate = this.convertToISODate(this.filterEndDate);
        const deadline = new Date(newDate);
        deadline.setHours(0, 0, 0, 0);

        return date > deadline;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-edit::v-deep {
  background: #def2f1 !important;
  border-color: #def2f1 !important;
  color: #008479 !important;
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.cell-overflow {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
<style lang="scss">
p {
  margin-bottom: 0;
}
</style>
