export const tableMixin = {
  data() {
    return {
      dateFomartion: 'DD/MM/YYYY',

      column: [
        {
          key: 'coachDate',
          label: 'Ngày coach',
          sortable: true,
          style: {
            width: '140px',
          },
        },
        {
          key: 'personCoach',
          label: 'Người coach',
          sortable: false,
          style: {
            width: '15%',
          },
        },
        {
          key: 'comment',
          label: 'Nhận xét',
          sortable: false,
        },
        {
          key: 'actionDropdown',
          label: '',
          sortable: false,
          style: {
            width: '5%',
          },
        },
      ],
    };
  },

  methods: {
    formatDate(date) {
      const isValid = this.$moment(date, 'MM/DD/YYYY HH:mm:ss').isValid();
      if (!isValid) return null;
      return this.$moment(date).format('DD/MM/YYYY');
    },
  },
};
